/* You can add global styles to this file, and also import other style files */

@import "./variables.scss";

// @import url('./lib/Semantic-UI-CSS-master/semantic.min.css');
*:not(i) {
  // font-family: "Lato", sans-serif !important;
  font-family: "Libre Franklin", Helvetica, sans-serif !important;
}

/**********
Structure
**********/

.app{
  min-width: 1000px;
}

body {
  // min-width: 1024px !important; // background-color: rgba(229, 233, 237, 1.0);
  overflow-x: scroll !important;
  background-color: #e2e2e2;
}

.content-header {
  padding-top: 3em;
  padding-bottom: 2em;
  background-color: #fff;
  // color: $text-dark-gray;

  & .container {
    // padding-left: 1em;
    // padding-right: 1em;
    // min-width: 1024px;
  }
}

.ui.app.container {
  padding-left: 3em;
  padding-right: 3em;

  padding-bottom: 5em;

  &.page {}

  &.main {
    margin-bottom: 2em;
    padding-bottom: 2em; //   min-width: 1024px !important;
    min-height: 100% !important; // background-color: #fff;
    background-color: #ffffff;
    // min-width: 990px;

  }
}

.page-header {
  margin-bottom: 1.1em !important;
  padding-bottom: 0.4em !important;
}

.ui.header {
  margin-bottom: 0.5rem;

  &.light {
    font-weight: 400 !important;
  }
}

[data-tooltip][data-inverted]:after {
  background-color: rgba(0, 0, 0, 0.75);
}

/********************
  Common UI
********************/

.uppercase {
  text-transform: uppercase !important;
}

a {
  cursor: pointer;
  color: $menu-active;
}

a:hover {
  color: $menu-hover;
}

.ui.button {
  font-weight: 400 !important;
}

.ui.menu {
  & .active {
    font-weight: 400 !important;
  }
}

.divider {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 2em;
  margin-top: 2em;
}

.button-area {
  margin-top: 1.5em;
}

.ui.segment:not(.raised) {
  box-shadow: none !important;
}

.ui.form {
  & label {
    font-size: 1em !important;
  }

  & .label-description {
    color: $text-dark-gray;
    margin-bottom: 0.5em;
  }

  & .field-header {
    font-weight: 500 !important;
    margin-bottom: 0.75em !important;
  }

  & textarea {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
  }

  & .checkboxes {
    & .field {
      margin-bottom: 0.5em !important;
    }
  }

  & .checkbox {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
}

.warning {
  color: #ffb500;
}

.message {
  & i.warning {
    color: #912d2b;
    opacity: 1 !important;
  }
}

.checkmark,
.check {
  color: #00ad00;
}

.hidden {
  display: none;
}

i.help {
  margin-left: 0.25em;
  color: #9e9e9e;
}

.header-row {
  font-weight: 500;
  background-color: $header-gray-light;
}

.sub-header-row {
  background-color: #fbfbfb;
}

.ui.label {
  font-weight: 500;
}

.font-weight-500 {
  font-weight: 500;
}


.font-weight-600 {
  font-weight: 600;
}

.ui.basic.button.as-link {
  border: none !important;
  font-size: 1em;
  color: $menu-active !important;
  box-shadow: none;
  padding: 0 !important;
  margin-left: 0.2em;
  margin-right: 0.2em;

  &:hover {
    color: #1e70bf !important;
    background: none !important;
  }
}

.section-title {
  font-size: 1.7em;
  margin-bottom: 0.5em;
}


.segment-header {
  padding: 1.25em 1em;
  background-color: #e8e8e8 !important;
  color: #505050 !important;
  border-color: #d4d4d5 !important;
  font-weight: 400 !important;
}

.ui.segment {
  // border-radius: 0 !important;
  font-size: 1em;
}

.ui.top.header {
  // border-radius: 0 !important;
}

.ui.horizontal.flat.menu,
.ui.vertical.flat.menu {
  border-radius: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  & .item {
    padding-top: 1em;
    padding-bottom: 1em;
    border-radius: 0 !important; // &:hover {

    //     background-color: #004b94;
    //     color: #fff;
    //     border: 1px solid #004b94;
    // }
    &.active {
      background-color: #1e70bf;
      color: #fff;
      border: 1px solid #1e70bf;
      font-weight: 400 !important;

      &:hover {
        background-color: #1e70bf;
        color: #fff;
        border: 1px solid #1e70bf;
      }
    }
  }
}


.question.icon {
  color: #999;
  margin-left: 0.3em;
}

.exclamation.icon {
  margin-right: 0.65em;
}


.ui.message {
  word-break: break-word;

  & .exclamation.icon {
    margin-right: 0.65em;
  }
}

// .settings-area {
//     & .setting-description {
//         margin-bottom: 1em;
//     }
//     & .column {
//         text-align: center;
//     }
//     & .selected-box {
//         border: 3px solid #004b94 !important;
//         background-color: #fff !important;
//     }
// }
.updatingForm {
  margin-right: 1em;
}

.ui.button.delete-button,
.ui.button.red-button {
  // background-color: #d53131 !important;
  background-color: #c20000 !important;
  color: #fff !important;
  border-color: #c20000 !important;

  &:hover,
  &:active {
    background-color: #c20000 !important;
    border-color: #c20000 !important;
    color: #fff !important;
  }
}

.tooltip-wrapper {
  display: inline-block;
}

.right-button {
  float: right;
}

.ui.label.pointing.below {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.ui.pointing.left.label:before {
  left: 10% !important;
}


.centered {
  text-align: center !important;
}


// /***************
// Public page
// ****************/
// .public-page {

//   color: $text-dark-gray;

//   & .title {
//     text-align: center;
//     font-size: 1.5em;
//     color: $text-dark-gray;
//     margin-top: 1em;
//     margin-bottom: 1em;
//   }

// }

//Default table format, used with .ui.table
//horizontal lines only
.ui.table.cl-table {

  & tr, tr:first-child {

    & th,
    td {
      border: 0 !important;
      border-bottom: 1px solid #dbdbdb !important;
      border-top: 1px solid #dbdbdb !important;
    }
  }

}

/*************
Footer
***************/
.footer {
  height: 50px;
}

/***************
 Text 
*****************/

.description-text {
  color: rgba(0, 0, 0, 0.6);
}

/****************
Normalize Table Styles
****************/

.ui.table {
  border-collapse: collapse;


  &.celled {}

  & thead,
  tbody {
    border: none !important;
  }


  & thead th {
    font-weight: 400;
    border: 1px solid #dbdbdb !important;
  }

  & tr,
  tr:first-child {
    box-shadow: none !important;
    border: none !important;

    & td {
      border: 1px solid #dbdbdb !important;
    }
  }



  & tr.header-row {
    background-color: $header-gray-light;
  }

  &.no-border {
    border: none !important;

    & tr {
      & td {
        border: none !important;
      }
    }
  }

  &.basic {
    border: none !important;
  }



}

/***************
Error Message
****************/

.error-message {
  margin-top: 1em;

  & .content {
    margin-top: 0.75em;
    margin-bottom: 0.5em;

    & .header {
      font-size: 1.2em;
    }

    & .title {
      font-weight: 600;
    }

    & .message {}
  }
}



/*******************
 Loader
  *****************/

.loader-segment {
  padding: 2em;
  text-align: center;
  color: #666;

  & i.fa-spin {
    margin-bottom: 1em;
    color: #999;
  }
}

/*******************
  Step
  *****************/

.user-step {
  margin-bottom: 2em;
  margin-top: 1em;

  & .user-step-instruction {
    margin-bottom: 1.5em;
  }

  & .add-button-area {
    padding-top: 0.5em;
  }

  & .nav-button-area {
    border-top: 1px solid #ccc;
    margin-top: 2em;
    padding-top: 1.5em;
    padding-bottom: 2.5em;

    & .prev-step-button {
      float: left;
    }

    & .next-step-button {
      float: right;

      &:after {
        clear: both;
      }
    }
  }
}

/*************************
  Mapping Step Container
*************************/

.step-container {
  margin-bottom: 0.65em;

  & .section-title {
    margin-bottom: 1em;
    margin-top: 1em;
    font-size: 1.2em;
    color: #bbb;

    & i.step-completed {
      font-size: 1.5em;
      vertical-align: middle;
      color: #00ad00;
      margin-left: 0.5em;
      opacity: 0.5;
    }

    & i.step-incomplete {
      font-size: 1.5em;
      vertical-align: middle;
      color: #ff9100;
      margin-left: 0.5em;
      opacity: 0.5;
    }
  }

  & .step-number {
    padding: 0.3em 0.8em;
    border: 1px solid #bbb;
    background-color: #bbb;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-right: 1em;
    color: #fff;
  }

  & .step-content-container {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-left: 1em;
    border-left: 1px solid #ccc;

    &.last {
      border-left: none;
    }
  }

  & .step-content {
    padding-left: 2em;
    display: none;

    & .button-area {
      padding-bottom: 1em;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      & .previous.button {
        float: right;
      }

      & .next.button {
        float: right;
      }

      & .action.button {
        float: left;
        margin-right: 1.25em;
      }

      & .status-text {
        padding-top: 8px;
        font-style: italic;
        float: left;

        & i {
          margin-right: 0.5em;
        }
      }
    }

    & .saving {
      padding-top: 1em;
      text-align: right;
      line-height: 30px;
      vertical-align: middle;

      & i {
        margin-right: 0.75em;
        font-size: 1.75em;
        vertical-align: middle;
        margin-bottom: 3px;
      }

      &.in-progress {
        color: #999;
      }

      &.complete {
        color: #00ad00;
      }
    }
  }

  & .step-instruction {
    margin-bottom: 0.75em;
  }

  & .step-actions {
    text-align: right;
  }

  &.active {
    & .section-title {
      color: #000;

      & i.step-completed {
        opacity: 1;
      }

      & i.step-incompleted {
        opacity: 1;
      }

      & .step-number {
        border: 1px solid #00ad00;
        background-color: #00ad00;
        color: #fff;
      }
    }

    & .step-content {
      display: block;
    }
  }
}

/****************
  form
*****************/

.form-area {
  & .field-instruction {
    margin-bottom: 0.5em;
  }
}

.required-field {
  margin-left: 0.15em;
  color: #db2828;
}

/******************
  Modal
******************/

.ui.modal {
  & .header {
    font-weight: 400 !important;
  }
}

.modal-window {
  z-index: 1000;
  position: fixed;
  right: 0;
  left: 0;
  top: 10%;
  margin-right: auto;
  margin-left: auto; // min-height: 200px;
  max-height: calc(100vh - 20%);
  overflow-y: auto;
  width: 85%;
  max-width: 900px;
  display: none;
  /*
  visibility: hidden;

  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  */
  /*
  transform: scale(0.5);
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  */
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  &.second {
    top: 7%;
    width: 80%;
    max-width: 850px;
  }

  & .modal-instruction {
    margin-bottom: 1em;
  }

  & .modal-window-content {
    padding: 2em;

    & .description {
      margin-bottom: 1em;
    }
  }

  &.show {
    display: block;
    /*
    visibility: visible;

    opacity: 1.0;

    transform: scale(1.0);
    */
  }

  &.hide {
    display: none;
    /*
    visibility: hidden;
 
    opacity: 0.0;

    transform: scale(0.5);
    */
  }

  & .modal-actions {
    text-align: right;
    margin-top: 0em;
    padding: 1.5em 2em;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    background-color: #f7f7f7;

    & .updating-text {
      padding-right: 1em;
    }
  }

  & .modal-window-header {
    padding: 1em 2em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    font-size: 1.3em;
    background-color: #f3f4f5;
  }
}

.in-progress-modal-window {
  color: #fff;
  text-align: center;
  z-index: 1000;
  position: fixed;
  right: 0;
  left: 0;
  top: 20%;
  margin-right: auto;
  margin-left: auto;
  min-height: 200px;
  max-height: calc(100vh - 40%);
  overflow-y: auto;
  width: 85%;

  & .text {
    margin-top: 1em;
  }
}

/******************
 Subsection
******************/

.subsection {
  margin-top: 1em;
  margin-bottom: 1.25em;
}

.subsection-title {
  margin-bottom: 0.25em;

  & a {
    font-weight: normal;
    margin-left: 0.5em;
  }
}

/**************************************************************
 Tooltips: https://www.w3schools.com/css/css_tooltip.asp
**************************************************************/

.tooltip {
  position: relative;
  display: inline-block;
  font-weight: normal !important;

  &:hover .tooltip-text {
    visibility: visible;
  }
}

.tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 0.5em;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;

  &.bottom {
    top: 100%;
    left: 50%;
    margin-left: -150px;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
  }

  &.top {
    bottom: 100%;
    left: 50%;
    margin-left: -150px;

    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }

  &.right {
    top: -5px;
    left: 105%;
  }

  &.left {
    top: -5px;
    right: 105%;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%;
      /* To the right of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
    }
  }
}

.tooltip-text.bottom {}

.tooltip-text.top {}


@media only screen and (min-width: 768px) {
  .ui.container {
    width: 100%;
  }
}


@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1127px !important;
  }
}

@media only screen and (min-width: 1300px) {
  .ui.container {
    width: 1227px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .ui.container {
    width: 1450px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .ui.container {
    // width: 1650px;
  }
}

@media only screen and (min-width: 1900px) {
  .ui.container {
    // width: 1850px;
  }
}

@media only screen and (min-width: 2100px) {
  .ui.container {
    // width: 2050px;
  }
}

@media print {
  body {
    background-color: #fff !important;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    font-size: 14pt;
    line-height: 18pt;
  }

  a {
    color: rgba(0, 0, 0, 0.87);
  }

  .ui.main.container {
    padding-top: 0 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .review-selection-menu {
    display: none;
  }

  .content-header {
    border-bottom-width: 0 !important;
  }

  button {
    display: none !important;
  }

  .ui.breadcrumb {
    display: none !important;
  }

  .ui.segment {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .ui.grid.review-menu-section {
    display: none !important;
  }
}
