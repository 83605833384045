/** Color Variables ****/

//menu
// $menu-active: #4183c4;
$menu-active: #4292e0;
$menu-hover: #006dd8;
$menu-inactive: #b5b5b5;
// $menu-hover: #7f7f7f;
//text
$text-gray-light: #a9a9a9;
$text-gray: #949494;
$text-dark-gray: #767676;
//header
$header-gray-dark: #dedede;
$header-gray: #e2e2e2;
$header-gray-light: #eaeaea;
$header-gray-very-light: #f6f6f6;
//status
$in-progress-color: #2185d0;
$attention-orange: #ff7900;
$menu-active-background: #395a8d;

//colors
$dark-red: #B03A2E;
$check-green: #00ad00;


$course-outcomes-background-color: #f7f7f7;
$plo-background-color: #eaeaea;
$course-row-background-color: #e2e2e2;
